import { Navigate } from 'react-router-dom';
import jwt from 'jwt-decode'
import ErrorPage from '../Container/ErrorPage/ErrorPage';

const UserRoute = ({ children }) => {
    const auth = sessionStorage.idToken
    const token: any = sessionStorage.getItem("idToken");
    let isUser, isAdmin, isCommon, USER_TYPE
    if (token) {
        let decoded: any = jwt(token)
        let groups = decoded["cognito:groups"]
        isUser = groups?.includes("AWSTOOLS_USERS_GROUP")
        isAdmin = groups?.includes("AWSTOOLS_ADMIN_GROUP")
        isCommon = groups?.every(element => {
            return ["AWSTOOLS_USERS_GROUP", "AWSTOOLS_ADMIN_GROUP"].includes(element)
        })
        
        if (isCommon) {
            USER_TYPE = "Admin"
        } else if (isAdmin) {
            USER_TYPE = "Admin"
        } else if (isUser) {
            USER_TYPE = "User"
        }
    }
    if (!auth) {
        return <Navigate to="/" replace />;
    }
    if (USER_TYPE === "User") {
        return children;
    } else {
        return <ErrorPage />
    }
};

export default UserRoute;