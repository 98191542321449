import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AssignedUsers from "./AssignedUsers";
import UnassignedUsers from "./UnassignedUsers";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { BottomNavigation, Box } from '@mui/material';
import { ApiService } from '../../Services/boralSelfServeApis'
const apiService = new ApiService()

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function InstanceModal(props) {

    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [unassignedUserSelect, setUnassignedUserSelect] = React.useState<readonly string[]>([]);
    const [disable, setDisable] = React.useState(false)

    const token = sessionStorage.getItem("idToken")

    const header = {
        headers: {
            Authorization: token,
        }
    };

    const handleRemove = async () => {
        setDisable(true)
        await apiService.unassignUsers({ users: props.checkedUsers, instanceId: props.instanceId }, header)
        toast.success("Request accepted for removing the user");
        setDisable(false)
        props.getUsersList(props)
        props.setUserSelected([])
        props.setCheckedUsers([])
        setSelected([])
        setUnassignedUserSelect([])
    }

    const handleAssignPayload = {
        usersData: props.userSelected,
        instancesData: props.instanceSelected
    }

    const handleAssign = async () => {
        try {
            setDisable(true)
            await apiService.assignUsers(handleAssignPayload, header)
            toast.success("Request accepted for mapping users to instances");
            setDisable(false)
            props.getUsersList(props)
            props.setUserSelected([])
            props.setCheckedUsers([])
            setSelected([])
            setUnassignedUserSelect([])
        } catch (err) {
            toast.error("Something went wrong")
        }
    }

    return (
        <>
            <div>
                <Dialog
                    fullScreen
                    open={props.open}
                    onClose={props.handleCloseStart}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {props.instanceName}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <AssignedUsers
                        instanceTableData={props.instanceTableData}
                        checkedInstances={props.checkedUsers}
                        setCheckedInstances={props.setCheckedUsers}
                        loading={props.loading}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    <UnassignedUsers
                        unassignedUsers={props.unassignedUsers}
                        userSelected={props.userSelected} s
                        setUserSelected={props.setUserSelected}
                        loading={props.loading}
                        selected={unassignedUserSelect}
                        setSelected={setUnassignedUserSelect}
                    />
                    <Box sx={{ width: '100%', position: 'fixed', bottom: 0 }}>
                        <BottomNavigation style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div className="footer" style={{ marginRight: '80px' }}>
                                <div className="actionButton" style={{ marginRight: '10px' }}>
                                    {!(Boolean(props.checkedUsers.length && props.userSelected?.length)) && props.userSelected?.length ? <Button disabled={disable} variant="contained" color="success" onClick={handleAssign}>
                                        Assign
                                    </Button> : ""}
                                    {!(Boolean(props.checkedUsers.length && props.userSelected?.length)) && props.checkedUsers.length ? <Button disabled={disable} variant="contained" color="success" onClick={handleRemove}>
                                        Remove
                                    </Button> : ""}
                                </div>
                                <Button variant="outlined" color="error" onClick={props.handleCloseStart}>
                                    Cancel
                                </Button>
                            </div>
                        </BottomNavigation>
                    </Box>
                </Dialog>
            </div>
            <ToastContainer position='bottom-left' />
        </>
    );
}