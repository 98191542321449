import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jwt from "jwt-decode"

const AuthVerify: any = (props) => {
  let location = useLocation();
  const token: any = sessionStorage?.getItem('idToken')
  let decoded
  if(token) {
      decoded = jwt(token)
  }
  useEffect(() => {
      if (decoded?.exp * 1000 < Date.now()) {
        props?.logOut();
      }
  }, [decoded?.exp, location, props]);
  return ;
};

export default AuthVerify;