import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { SetStateAction } from 'react';
import MultilineTextFields from '../../Components/MultiLineTextField/MultilineTextFields';

export const StopInstance = (props) => {
    return (
        <div className="stop-instance">
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 300,
                        height: 200
                    },
                }}
            >
                <Paper>
                    <div className="stop-details">
                        <div className="reason">Reason</div>
                        <div className="reason-field"><MultilineTextFields width="200px" value={props.stopReason}
                            onChange={(e: { target: { value: SetStateAction<string>; }; }) => props.setStopReason(e.target.value)} />
                        </div>
                    </div>
                </Paper>
            </Box>
        </div>
    )
}
