import { SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MultilineTextFields from '../../Components/MultiLineTextField/MultilineTextFields';
import SelectLabels from '../../Components/SelectLabels/SelectLabels';
import './startInstance.css'

export const StartInstance = ({ duration, setDuration, startReason, setStartReason }) => {
    var data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    return (
        <>
            <div className="start-instance">
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            maxWidth: 450,
                            height: 300
                        },
                    }}
                >
                    <Paper>
                        <div className="start-details">
                            <div className="duration-field">
                                <div className="duration">Duration (hours)</div>
                                <div className="reason-field">
                                    <SelectLabels selectData={data} minWidth={245} defaultValue={data[0]} value={duration}
                                        onChange={(e: { target: { value: SetStateAction<number>; }; }) => setDuration(e.target.value)} />
                                </div>
                            </div>
                            <div className="start-reason">
                                <div className="reason">Reason</div>
                                <div className="reason-field">
                                    <MultilineTextFields width="250px" value={startReason}
                                        onChange={(e: { target: { value: SetStateAction<string>; }; }) => setStartReason(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Box>
            </div>
        </>
    )
}
