import jwt from 'jwt-decode'
import { Grid, GridCell } from "@arterial/layout-grid";
import "./Login.css";
import logo from "../../Assets/logo.svg"
import { sendTokenRequest } from "../../Services/TokenService"
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
const msLogin = require("../../Assets/ms.png") as string;

export const Login = () => {

  const navigate = useNavigate()

  const handleRedirect = () => {
    window.location.assign(`https://${process.env.REACT_APP_DOMAIN_NAME}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`)
  }

  const code = new URL(window.location.href).searchParams.get("code");
  if (code) {
    sendTokenRequest(code)
      .then(response => {
        if (response) {
          let idToken = response[0]?.access_token
          sessionStorage.setItem("idToken", idToken)
          let decoded, groups
          if (typeof idToken == 'string') {
            decoded = jwt(idToken)
            if (decoded !== undefined || null) {
              groups = decoded["cognito:groups"]
            }
          }
          if (groups?.includes("AWSTOOLS_USERS_GROUP") && groups?.includes("AWSTOOLS_ADMIN_GROUP")) {
            navigate('/admin');
          } else if (groups?.includes("AWSTOOLS_USERS_GROUP")) {
            navigate('/remote-control-switch');
          } else if (groups?.includes("AWSTOOLS_ADMIN_GROUP")) {
            navigate("/admin")
          }
        }
      })
      .catch((error => {
        console.log("TOKEN REQUEST ERROR", error);
      }));
  }

  return (
    <>
      <div className="identitylogin">
        <div className="bg">
          <div className="container">
            <div className="logoContainer">
              <img className="logo" src={logo as any} alt="Boral-Logo" />
            </div>
            <Grid className="grid">
              <GridCell span={3} />
              <GridCell span={6} className="loginForm">
                <div className="loginMessage">Sign in with</div>
                <div
                  id="office365-signin-button"
                  role="button"
                  className="signInMicrosoft"
                >
                  <Button onClick={handleRedirect}>
                    <img className="msLoginButton" src={msLogin} alt="" />
                  </Button>
                </div>
              </GridCell>
              <GridCell span={3} />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
