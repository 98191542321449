import { useState, useEffect } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Button, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { StartInstance } from '../StartInstance/StartInstance'
import { StopInstance } from '../StopInstance/StopInstance';

import { ApiService } from '../../Services/boralSelfServeApis'
const apiService = new ApiService()


export const DialogBox = (props) => {

    const [open, setOpen] = useState(false);
    const [openStopWindow, setOpenStopWindow] = useState(false)
    const [duration, setDuration] = useState(1)
    const [stopReason, setStopReason] = useState("")
    const [startReason, setStartReason] = useState("")
    const [disable, setDisable] = useState(false)
    // const [credentials, setCredentials] = useState({})



    const token = sessionStorage.getItem('idToken')

    const header = {
        headers: {
            Authorization: token,
        }
    };

    // useEffect(() => {
    //     getAccessToken(token, "ap-southeast-2:f356503e-c032-48d2-8fc4-d7773c4904f8", "ap-southeast-2_FhC44YWGR", (data) => {
    //         setCredentials(data)
    //     })
    // }, [])


    const stoppedInstance = (currentValue) => currentValue === "stopped";
    const stopped = props.selectedStatus.every(stoppedInstance);

    const runningInstance = (currentValue) => currentValue === "running";
    const started = props.selectedStatus.every(runningInstance);

    const startPayload = {
        instanceIds: props.selectedIds,
        startReason: startReason,
        duration: duration,
        accountName: props.accountName,
        // credentials: credentials
    }

    const stopPayload = {
        instanceIds: props.selectedIds,
        stopReason: stopReason,
        accountName: props.accountName
    }

    const handleStart = (e) => {
        setDisable(true)
        e.preventDefault()
        apiService.startInstances(startPayload, header)
            .then(res => {
                if (res.status === 200) {
                    toast.success("Request accepted for starting the instances")
                    setDisable(false)
                    setOpen(false);
                    props.initializeData();
                    props.setSelected([])
                    props.setSelectedIds([])
                    props.setSelectedStatus([])
                }
            })
            .catch(err => {
                toast.success("Request accepted for starting the instances")
                setDisable(false)
                setOpen(false);
                props.initializeData();
                props.setSelected([])
                props.setSelectedIds([])
                props.setSelectedStatus([])
            });
    }

    const handleStop = (e) => {
        setDisable(true)
        e.preventDefault()

        apiService.stopInstances(stopPayload, header)
            .then(res => {
                if (res.status === 200) {
                    toast.success("Request accepted for stopping the instances")
                    setDisable(false)
                    setOpenStopWindow(false);
                    props.initializeData();
                    props.setSelected([])
                    props.setSelectedIds([])
                    props.setSelectedStatus([])
                }
            }).catch(err => {
                toast.error("Something went wrong while stopping the instances")
                setDisable(false)
            });
    }

    const handleClickStartOpen = () => {
        setOpen(true);
    };

    const handleCloseStart = () => {
        setOpen(false);
    };

    const handleClickStopOpen = () => {
        setOpenStopWindow(true);
    };

    const handleCloseStop = () => {
        setOpenStopWindow(false);
    };

    return (
        <>
            {props.selected.length ? <div className="dialog" style={{ width: "80px", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                {stopped && <div>
                    <Button style={{ maxWidth: '60px', maxHeight: '30px', minWidth: '60px', minHeight: '30px', fontSize: 12 }}
                        variant="contained" color="success" onClick={handleClickStartOpen}>
                        Start
                    </Button>
                    <Dialog open={open} onClose={handleCloseStart}>
                        <DialogTitle>
                            <div className="title">
                                <b>Start Instance</b>
                            </div>
                            <p style={{ fontSize: 14 }}>Selected Instances</p>
                            <p className='selected-names' style={{ fontSize: 12, color: "green", maxWidth: 350 }}>
                                {props.selected.join(', ')}
                            </p>
                        </DialogTitle>
                        <form onSubmit={handleStart}>
                            <StartInstance duration={duration} setDuration={setDuration} startReason={startReason} setStartReason={setStartReason} />
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginBottom: "8px" }}>
                                <Button disabled={disable} style={{ marginRight: "10px" }} variant="contained" color="success" type="submit">Submit</Button>
                                <Button disabled={disable} style={{ marginRight: "8px" }} variant="outlined" color="error" onClick={handleCloseStart}>Cancel</Button>
                            </div>
                        </form>
                    </Dialog>
                </div>}
                {started && <div>
                    <Button style={{ maxWidth: '60px', maxHeight: '30px', minWidth: '60px', minHeight: '30px', fontSize: 12 }}
                        variant="contained" color="error" onClick={handleClickStopOpen}>
                        Stop
                    </Button>
                    <Dialog open={openStopWindow} onClose={handleCloseStop}>
                        <DialogTitle><div className="title">
                            Stop Instance
                        </div>
                            <p style={{ fontSize: 14 }}>Selected Instances</p>
                            <p className='selected-names' style={{ fontSize: 12, color: "red", maxWidth: 350 }}>
                                {props.selected.join(', ')}
                            </p>
                        </DialogTitle>
                        <form onSubmit={handleStop}>
                            <StopInstance stopReason={stopReason} setStopReason={setStopReason} />
                            <div className="footer" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginBottom: "8px" }}>
                                <Button disabled={disable} style={{ marginRight: "10px" }} variant="contained" color="success" type="submit">Submit</Button>
                                <Button disabled={disable} style={{ marginRight: "8px" }} variant="outlined" color="error" onClick={handleCloseStop}>Cancel</Button>
                            </div>
                        </form>
                    </Dialog>
                </div>}
            </div> : ""}
            <ToastContainer position='bottom-left' />
        </>
    )
}
