import TextField from '@mui/material/TextField';

export default function MultilineTextFields(props: any) {
  return (
    <div>
      <TextField
        required
        id="outlined-multiline-flexible"
        multiline
        maxRows={4}
        value={props.reason}
        onChange={props.onChange}
        style={{ width: props.width }}
      />
    </div>
  );
}