import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AssignedInstances from './AssignedInstances';
import UnassignedInstances from "./UnassignedInstances"
import BottomNavigation from '@mui/material/BottomNavigation';
import Box from '@mui/material/Box';
import AutoComplete from '../../Components/SelectLabels/Autocomplete'
import { toast } from 'material-react-toastify';
import { ApiService } from '../../Services/boralSelfServeApis'
import { accountNames } from './Admin.constant'
const apiService = new ApiService()

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [unassignedInstanceSelect, setUnassignedInstanceSelect] = React.useState<readonly string[]>([]);
    const [disable, setDisable] = React.useState(false)

    const token = sessionStorage.getItem('idToken')

    const header = {
        headers: {
            Authorization: token,
        }
    };

    const handleRemove = async () => {
        setDisable(true)
        await apiService.unAssignInstances({ username: props.userName, instances: props.checkedInstances }, header)
        toast.success("Request accepted for removing the instances");
        setDisable(false)
        props.getInstancesList(props)
        props.setCheckedInstances([])
        setSelected([])
        setUnassignedInstanceSelect([])
    }

    const handleAssignPayload = {
        usersData: props.userSelected,
        instancesData: props.instanceSelected
    }

    const handleAssign = async () => {
        try {
            setDisable(true)
            await apiService.assignInstances(handleAssignPayload, header)
            toast.success("Request accepted for mapping users to instances");
            setDisable(false)
            props.getInstancesList(props)
            props.setInstanceSelected([])
            props.setCheckedInstances([])
            props.setCheckedInstancesId([])
            setSelected([])
            setUnassignedInstanceSelect([])
        } catch (err) {
            toast.error("Something went wrong")
        }
    }

    const handleCloseStart = () => {
        props.setSelected([])
        setSelected([])
        props.setCheckedInstances([])
        props.setUserSelected([])
        props.setInstanceSelected([])
        props.setOpen(false);
        setUnassignedInstanceSelect([])
    };

    return (
        <>
            <div>
                <Dialog
                    fullScreen
                    open={props.open}
                    onClose={handleCloseStart}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {props.emailId}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className="account-dropdown" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30vw", margin: "20px 0px 0px 70px" }} >
                        <div className="label">
                            Select Account
                        </div>
                        <div>
                            <AutoComplete value={props.accountName} options={accountNames} minWidth={400} size="small" label="Select"
                                onChange={(newValue, propName) => {
                                    props.setAccountName(newValue)
                                }} />
                        </div>
                    </div>
                    {props.accountName?.length && <div>
                        <AssignedInstances
                            userTableData={props.userTableData}
                            checkedInstances={props.checkedInstances}
                            setCheckedInstances={props.setCheckedInstances}
                            checkedInstancesId={props.checkedInstancesId}
                            setCheckedInstancesId={props.setCheckedInstancesId}
                            loading={props.loading}
                            getInstancesList={props.getInstancesList}
                            setSelected={setSelected}
                            selected={selected}
                        />
                        <UnassignedInstances
                            unassignedInstances={props.unassignedInstances}
                            instanceSelected={props.instanceSelected}
                            setInstanceSelected={props.setInstanceSelected}
                            loading={props.loading}
                            accountName={props.accountName}
                            setAccountName={props.setAccountName}
                            getInstancesList={props.getInstancesList}
                            setSelected={setUnassignedInstanceSelect}
                            selected={unassignedInstanceSelect}
                        />
                    </div>}
                    <Box sx={{ width: '100%', position: 'fixed', bottom: 0 }}>
                        <BottomNavigation style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div className="footer" style={{ marginRight: '80px' }}>
                                <div className="actionButton" style={{ marginRight: '10px' }}>
                                    {!(Boolean(props.checkedInstances.length && props.instanceSelected.length)) && props.instanceSelected?.length ? <Button disabled={disable} variant="contained" color="success" onClick={handleAssign}>Assign</Button> : ""}
                                    {!(Boolean(props.checkedInstances.length && props.instanceSelected.length)) && props.checkedInstances?.length ? <Button disabled={disable} variant="contained" color="success" onClick={handleRemove}>
                                        Remove
                                    </Button> : ""}
                                </div>
                                <Button variant="outlined" color="error" onClick={handleCloseStart}>
                                    Cancel
                                </Button>
                            </div>
                        </BottomNavigation>
                    </Box>
                </Dialog>
            </div >
        </>
    );
}