export const accountNames = [
    "boral-cloudops-build",
    "boral-sandpit",
    "boral-assetmanagement-nonprod",
    "boral-assetmanagement-prod",
    // "boral-audit",
    // "boral-backup",
    "boral-bdscybersecurity-nonprod",
    "boral-bdscybersecurity-prod",
    "boral-bigdata-nonprod",
    "boral-bigdata-prod",
    "boral-cloudendure",
    // "boral-cloudops-build",
    "boral-cloudops-nonprod",
    "boral-cloudops-prod",
    "boral-concrite-nonprod",
    "boral-concrite-prod",
    "boral-contactcentre-nonprod",
    "boral-contactcentre-prod",
    "boral-crm-nonprod",
    "boral-crm-prod",
    "boral-customerexp-nonprod",
    "boral-customerexp-prod",
    "boral-financial-nonprod",
    "boral-financial-prod",
    "boral-flitch-nonprod",
    "boral-flitch-prod",
    "boral-integration-nonprod",
    "boral-integration-prod",
    "boral-iot-nonprod",
    "boral-iot-prod",
    "boral-landingzone-nonprod",
    "boral-landingzone-prod",
    "boral-mobile-nonprod",
    "boral-mobile-prod",
    "boral-multitenant-nonprod",
    "boral-multitenant-prod",
    "boral-peopleeexp-nonprod",
    "boral-peopleexp-prod",
    "boral-qa-nonprod",
    "boral-qa-prod",
    "boral-rpa-nonprod",
    "boral-rpa-prod",
    // "boral-sandpit",
    "boral-supplychain-nonprod",
    "boral-supplychain-prod",
    "boral-vendor-landing"
]