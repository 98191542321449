import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectLabels(props: any) {
  return (
    <div>
      <FormControl sx={{ m: 0.5, minWidth: props.minWidth }} size={props.size}>
        <Select
          required
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={MenuProps}
          style={{ fontSize: 12 }}
        >
          {props?.selectData?.map((name: any, i: number) => (
            <MenuItem
              value={name}
              key={i}
              style={{ fontSize: 12 }}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}