import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ControllableStates(props) {
//   const [value, setValue] = React.useState<string | null>(options[0]);
  const [inputValue, setInputValue] = React.useState('');

  return (
    
    <div>
      {/* <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
      <div>{`inputValue: '${inputValue}'`}</div>
      <br /> */}
      <Autocomplete
        value={props.value || null}
        onChange={(event: any, newValue) => {
          //   setValue(newValue);
          props.onChange(newValue)
        }}
        defaultValue={props.defaultValue || null}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={props.options}
        sx={{ width: 260 }}
        renderInput={(params) => <TextField {...params} size="small" label={props.label}/>}
      />
    </div>
  );
}