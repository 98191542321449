import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import InstancesTable from './InstancesTable'
import './remoteControl.css'

const RemoteControl = () => {

  return (
    <>
      <div className="box">
        <InstancesTable />
        <ToastContainer position='bottom-left' />
      </div>
    </>
  )
}

export default RemoteControl;