import * as React from 'react';
import Box from '@mui/material/Box';
import logo from '../../Assets/boral.svg'
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { IconButton } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom'
import axios from 'axios'

const drawerWidth = 200;

export default function Dashboard() {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [credentials, setCredentials] = React.useState<any>()

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const token: any = sessionStorage.getItem('idToken')

    const header = React.useMemo(() => ({
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }), [token]);

    const getUserInfo = React.useCallback(async () => {
        const userInfo = await axios.get(`https://${process.env.REACT_APP_DOMAIN_NAME}/oauth2/userInfo`, header)
        setCredentials(userInfo)
        return userInfo
    }, [header])

    React.useEffect(() => {
        getUserInfo()
    }, [getUserInfo])

    const handleLogout = () => {
        sessionStorage.clear()
        window.location.assign(`https://${process.env.REACT_APP_DOMAIN_NAME}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`)
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar style={{ background: '#2E3B55' }} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="logoContainer">
                            <img className="logo" src={logo as any} alt="Boral-Logo" style={{ height: "40px", width: "160px" }} />
                        </div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <span style={{ fontSize: "16px", marginRight: "10px" }}>{credentials?.data?.email}</span><AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            <ListItemButton
                                key="Dashboard"
                                to="/admin"
                                component={Link}
                            >
                                <ListItemIcon>{<DashboardIcon />}</ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </List>
                    </Box>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                </Box>
            </Box>
        </>
    );
}