import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { CircularProgress, Stack, InputAdornment } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InstanceModal from './InstanceModal';
import { Button } from '@mui/material';
import _ from "lodash"
import Search from "./Search"
import SearchIcon from '@mui/icons-material/Search';
import AutoComplete from '../../Components/SelectLabels/Autocomplete'
import { ApiService } from '../../Services/boralSelfServeApis'
import { accountNames } from './Admin.constant'

interface Data {
    instanceName: string;
    instanceType: string;
    instanceId: string;
    status: string;
    boralSchedule: string;
    applicationName: string;
    settings: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'instanceName',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'instanceType',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'instanceId',
        numeric: false,
        disablePadding: false,
        label: 'Instance ID',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'boralSchedule',
        numeric: false,
        disablePadding: false,
        label: 'Boral Schedule',
    },
    {
        id: 'applicationName',
        numeric: false,
        disablePadding: false,
        label: 'Application Name',
    },
    {
        id: 'settings',
        numeric: false,
        disablePadding: false,
        label: 'Settings'
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontWeight: "bold" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Instances
                </Typography>
            )}
        </Toolbar>
    );
}

export default function Instances(props) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('instanceName');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [tableData, setTableData] = React.useState<any[]>([])
    const [isRefresh, setIsRefresh] = React.useState(false)
    const [accountName, setAccountName] = React.useState<any>(accountNames[0])
    const [open, setOpen] = React.useState(false);
    const [instanceTableData, setInstanceTableData] = React.useState([])
    const [unassignedUsers, setUnassignedUsers] = React.useState([])
    const [checkedUsers, setCheckedUsers] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [filterFunc, setFilterFunc] = React.useState({ fn: items => { return items; } })
    const [userSelected, setUserSelected] = React.useState([])

    const apiService = new ApiService()

    const getUsersList = React.useCallback(async (props) => {
        try {
            setLoading(true)
            const _userList = await apiService.getUsersList({ instanceId: props.instanceSelected[0]?.instanceId }, header)
            const _userData = _userList.data.data.assignedUsersList.items

            const _dynamoDbUserList = _userData?.map((k, i) => ({
                userName: k.PK.slice(5),
                email: k.email,
            }))

            const _response = await apiService.getUsers(header);
            const _users = _response.data.data.Users

            const _cognitoUserList = _users?.map((k, i) => ({
                userName: k.Username,
                email: k.Attributes.find(item => item.Name === 'email').Value,
            }))

            const unassignedUsers = _.differenceBy(_cognitoUserList, _dynamoDbUserList, 'userName')

            setInstanceTableData(_dynamoDbUserList)
            setUnassignedUsers(unassignedUsers)
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }, [])

    React.useEffect(() => {
        if (open) {
            getUsersList(props)
        }
    }, [open])

    const handleClickStartOpen = () => {
        setOpen(true);
    };

    const handleCloseStart = () => {
        setSelected([])
        setCheckedUsers([])
        props.setInstanceSelected([])
        setUserSelected([])
        setOpen(false);
    };

    const token: any = sessionStorage.getItem('idToken')

    const header = {
        headers: {
            Authorization: token,
        }
    };

    const getInstances = React.useCallback(async () => {
        try {
            setLoading(true)
            const _response = await apiService.getInstanceStatus({ accountName, instanceIds: [] }, header)
            const _instanceList = _response.data.data.Reservations

            const instanceData = _instanceList.map((k, i) => ({
                imageId: k.Instances[0]?.ImageId,
                instanceId: k.Instances[0]?.InstanceId,
                instanceName: k.Instances[0].Tags?.find(item => item.Key === 'Name')?.Value || "-",
                instanceType: k.Instances[0]?.InstanceType,
                status: k.Instances[0]?.State.Name,
                boralSchedule: k.Instances[0].Tags?.find(item => item.Key === 'BoralSchedule')?.Value || "-",
                applicationName: k.Instances[0].Tags?.find(item => item.Key === 'applicationName')?.Value || "-",
                accountName
            }))

            setTableData(instanceData)
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }, [accountName])


    React.useEffect(() => {
        getInstances();
    }, [getInstances, isRefresh]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = tableData?.map((n) => n);
            const newInstanceSelected = tableData?.map((n) => ({
                ...n
            }));
            setSelected(newSelected);
            props.setInstanceSelected(newInstanceSelected)
            return;
        }
        setSelected([]);
        props.setInstanceSelected([])
    };

    const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
        const selectedIndex = selected.indexOf(row);
        let newSelected: readonly string[] = [];
        let newInstanceSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
            newInstanceSelected = newInstanceSelected.concat(props.instanceSelected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newInstanceSelected = newInstanceSelected.concat(props.instanceSelected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newInstanceSelected = newInstanceSelected.concat(props.instanceSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            newInstanceSelected = newInstanceSelected.concat(
                props.instanceSelected.slice(0, selectedIndex),
                props.instanceSelected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        props.setInstanceSelected(newInstanceSelected)
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (row: any) => selected?.indexOf(row) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

    const runningIcon = <CheckCircleOutlineIcon sx={{ fontSize: "20px" }} />
    const stoppedIcon = <RemoveCircleOutlineIcon sx={{ fontSize: "20px" }} />
    const timerIcon = <AccessTimeIcon sx={{ fontSize: "20px" }} />

    const handleSearch = e => {
        let target = e.target;
        setFilterFunc({
            fn: items => {
                if (target.value == "")
                    return items;
                else
                    return items.filter(x => {
                        return x.instanceName?.includes(target.value)
                    }
                    )

            }
        })
    }

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Toolbar style={{ marginLeft: "-30px" }}>
                    <Search
                        label="Search by Instance Name"
                        InputProps={{
                            startAdornment: (<InputAdornment position='start'>
                                <SearchIcon sx={{ fontSize: "16px" }} />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                </Toolbar>
                <div className="account-dropdown">
                    <AutoComplete options={accountNames} value={accountName} minWidth={400} size="small"
                        onChange={(newValue, propName) => {
                            setAccountName(newValue)
                        }} />
                </div>
            </div>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected?.length} />
                    <TableContainer>
                        <Stack alignItems="center">
                            {loading && <CircularProgress />}
                        </Stack>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected?.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={tableData.length}
                            />
                            <TableBody>
                                {stableSort(filterFunc.fn(tableData), getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <>
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.instanceName}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.instanceName}
                                                    </TableCell>
                                                    <TableCell align="left">{row.instanceType}</TableCell>
                                                    <TableCell align="left">{row.instanceId}</TableCell>
                                                    <TableCell style={row.status === "running" ? { color: "green" } : row.status === "stopping" ? { color: "grey" } : row.status === "pending" ? { color: "grey" } : { color: "red" }} align="left">
                                                        {row.status === "running" ? <span className='statusStyle'>{runningIcon}Running</span> : row.status === "stopped" ? <span className='statusStyle'>{stoppedIcon}Stopped</span> : row.status === "stopping" ? <span className='statusStyle'>{timerIcon}Stopping</span> : row.status === "pending" ? <span className='statusStyle'>{timerIcon}Pending</span> : row.status === "terminated" ? <span className='statusStyle statusColorGrey'>{stoppedIcon}Terminated</span> : ""}
                                                    </TableCell>
                                                    <TableCell align="left">{row.boralSchedule}</TableCell>
                                                    <TableCell align="left">{row.applicationName}</TableCell>
                                                    <TableCell align="left">
                                                        <Button size="small" onClick={handleClickStartOpen}
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                <InstanceModal
                                                    getUsersList={getUsersList}
                                                    userSelected={userSelected}
                                                    setUserSelected={setUserSelected}
                                                    unassignedUsers={unassignedUsers}
                                                    open={open}
                                                    handleCloseStart={handleCloseStart}
                                                    instanceName={props?.instanceSelected[0]?.instanceName}
                                                    instanceId={props?.instanceSelected[0]?.instanceId}
                                                    instanceTableData={instanceTableData}
                                                    checkedUsers={checkedUsers}
                                                    setCheckedUsers={setCheckedUsers}
                                                    loading={loading}
                                                    instanceSelected={props.instanceSelected}
                                                />
                                            </>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box >
        </>
    );
}