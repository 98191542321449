import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Search(props) {

    const {name, label, value, error = null, onChange, ...other } = props;

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField 
            id="outlined-basic" 
            label={props.label} 
            variant="outlined" 
            size="small"
            onChange={onChange}
            {...other} 
            />
        </Box>
    );
}