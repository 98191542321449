import Dashboard from './Dashboard'
import RemoteControl from './RemoteControl'

function RemoteControlPage() {

    return (
        <>
            <Dashboard />
            <RemoteControl />
        </>
    )
}

export default RemoteControlPage