import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export class ApiService {

    getUsers = (header) => {
        return axios.get(`/users`, header)
    }

    getInstances = (accountName, header) => {
        return axios.post(`/instances`, accountName, header)
    }

    getAllInstances = (header) => {
        return axios.get(`/instances`, header)
    }

    getInstanceStatus = (accountName, header) => {
        return axios.post(`/instances/status`, accountName, header)
    }

    getUsersList = (instanceId, header) => {
        return axios.post(`/instances/users-list`, instanceId, header)
    }

    getInstanceList = (userDetails, header) => {
        return axios.post(`/users/instances-list`, userDetails, header)
    }

    unassignUsers = (userDetails, header) => {
        return axios.post(`/instances/remove`, userDetails, header)
    }

    assignUsers = (userDetails, header) => {
        return axios.put(`/users/instances`, userDetails, header)
    }

    unAssignInstances = (instanceDetails, header) => {
        return axios.post(`/users/instances-list/remove`, instanceDetails, header)
    }

    assignInstances = (instanceDetails, header) => {
        return axios.put(`/users/instances`, instanceDetails, header)
    }

    getAccountPermissions = (username, header) => {
        return axios.post(`/users/permissions/accounts`, username, header)
    }

    assignUserPermissions = (assignPayload, header) => {
        return axios.post(`/users/permissions`, assignPayload, header)
    }

    startInstances = (startPayload, header) => {
        return axios.post(`instances/start`, startPayload, header)
    }

    stopInstances = (stopPayload, header) => {
        return axios.post(`instances/stop`, stopPayload, header)
    }
}