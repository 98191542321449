import { Login } from "./Container/Login/Login";
import { Route, Routes } from "react-router-dom";
import { AdminDashboard } from "./Container/Admin/AdminDashboard"
import RemoteControlPage from "./Container/RemoteContolSwitch/RemoteControlPage";
import AdminRoute from "./Services/AdminRoute";
import UserRoute from "./Services/UserRoute";
import ErrorPage from "./Container/ErrorPage/ErrorPage"
import AuthVerify from "./Services/AuthVerify";

const App = () => {

  const autoLogout = () => {
    sessionStorage.clear()
    window.location.assign(`https://${process.env.REACT_APP_DOMAIN_NAME}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`)
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/remote-control-switch" element={<UserRoute><RemoteControlPage /></UserRoute>} />
        <Route path="/admin" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
      <AuthVerify logOut={autoLogout} />
    </>
  );
}

export default App;
