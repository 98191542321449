import axios from "axios";
import { CONFIG } from "./Config";

export const sendTokenRequest = (code) => {
    const body = [] as any[];
    body.push(`client_id=${CONFIG.CLIENT_ID}`);
    body.push(`client_secret=${CONFIG.CLIENT_SECRET}`);
    body.push(`code=${code}`);
    body.push(`grant_type=${CONFIG.GRANT_TYPE}`);
    body.push(`redirect_uri=${CONFIG.REDIRECT_URI}`);

    return axios.post(`https://${process.env.REACT_APP_DOMAIN_NAME}/oauth2/token`, body.join("&"), getTokenRequestHeaders())
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(new Error("Invalid status code received in the token response: "
                    + response.status));
            }
            return [response.data, decodeIdToken(response.data.id_token)];
        }).catch((error) => {
            return Promise.reject(error);
        });
};

const getTokenRequestHeaders = () => {
    return {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };
};

const decodeIdToken = (token) => {
    return JSON.parse(atob(token.split(".")[1]));
};