import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { CircularProgress, InputAdornment, Stack } from '@mui/material';
import { Button } from '@mui/material';
import UserModal from "./UserModal"
import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import Search from "./Search"
import SearchIcon from '@mui/icons-material/Search';
import _ from "lodash"
import { ApiService } from '../../Services/boralSelfServeApis'
const apiService = new ApiService()

interface Data {
    userName: string;
    email: string;
    createdAt: string;
    updatedAt: string
    settings: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'userName',
        numeric: false,
        disablePadding: true,
        label: 'Username',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Created At',
    },
    {
        id: 'updatedAt',
        numeric: false,
        disablePadding: false,
        label: 'Updated At',
    },
    {
        id: 'settings',
        numeric: false,
        disablePadding: false,
        label: 'Settings'
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox"></TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontWeight: "bold" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Users
                </Typography>
            )}
        </Toolbar>
    );
}

export default function Users(props) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('userName');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [open, setOpen] = React.useState(false);
    const [openPermissions, setOpenPermissions] = React.useState(false);
    const [userTableData, setUserTableData] = React.useState([]);
    const [checkedInstances, setCheckedInstances] = React.useState([])
    const [checkedInstancesId, setCheckedInstancesId] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [filterFunc, setFilterFunc] = React.useState({ fn: items => { return items; } })
    const [unassignedInstances, setUnassignedInstances] = React.useState([])
    const [accountName, setAccountName] = React.useState("")
    const [instanceSelected, setInstanceSelected] = React.useState([])
    const [permissionsTableData, setPermissionsTableData] = React.useState<AxiosResponse | []>([])

    props.setCheck(selected)

    const token = sessionStorage.getItem('idToken')

    const header = {
        headers: {
            Authorization: token,
        }
    };

    const getInstancesList = React.useCallback(async (props) => {
        try {
            setLoading(true)
            const _dynamoDbInstanceList = await apiService.getInstanceList({ username: props.userSelected[0]?.userName, accountName: accountName }, header)
            const _instanceData = _dynamoDbInstanceList.data.data.items

            const _ec2InstanceList = await apiService.getInstanceStatus({ accountName, instanceIds: [] }, header)
            const _instanceStatus = _ec2InstanceList.data.data.Reservations

            const _dynamoDbInstanceData = _instanceData?.map((k, i) => ({
                name: k.instanceName,
                type: k.instanceType,
                instanceId: k.SK.slice(9),
                status: k.status,
                applicationName: k.tags?.find(item => item.Key === 'applicationName')?.Value || "-",
                boralSchedule: k.tags?.find(item => item.Key === 'boralSchedule')?.Value || "-",
                accountName: k.accountName
            }))

            const _ec2InstanceData = _instanceStatus.map((k, i) => ({
                instanceName: k.Instances[0].Tags.find(item => item.Key === 'Name')?.Value || "-",
                instanceType: k.Instances[0]?.InstanceType,
                instanceId: k.Instances[0]?.InstanceId,
                status: k.Instances[0]?.State.Name,
                applicationName: k.Instances[0].Tags.find(item => item.Key === 'applicationName')?.Value || "-",
                boralSchedule: k.Instances[0].Tags.find(item => item.Key === 'BoralSchedule')?.Value || "-",
                accountName: accountName
            }))

            const unassignedInstances = _.differenceBy(_ec2InstanceData, _dynamoDbInstanceData, 'instanceId')

            setUserTableData(_dynamoDbInstanceData)
            setUnassignedInstances(unassignedInstances)
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }, [accountName])


    const getAccountPermissions = React.useCallback(async (props) => {
        let _response = await apiService.getAccountPermissions({ username: props.userSelected[0]?.userName }, header)
        setPermissionsTableData(_response.data.data.items)
    }, [])

    useEffect(() => {
        if (open) {
            getInstancesList(props)
        }
    }, [open, getInstancesList])


    useEffect(() => {
        if (openPermissions) {
            getAccountPermissions(props)
        }
    }, [openPermissions, getAccountPermissions])


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = props.tableData.map((n) => n);
            const newUserSelected = props.tableData.map((n) => ({
                ...n
            }));
            setSelected(newSelected);
            props.setUserSelected(newUserSelected)
            return;
        }
        setSelected([]);
        props.setUserSelected([])
    };

    const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
        const selectedIndex = selected.indexOf(row);
        let newSelected: readonly string[] = [];
        let newUserSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
            newUserSelected = newUserSelected.concat(props.userSelected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newUserSelected = newUserSelected.concat(props.userSelected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newUserSelected = newUserSelected.concat(props.userSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            newUserSelected = newUserSelected.concat(
                props.userSelected.slice(0, selectedIndex),
                props.userSelected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        props.setUserSelected(newUserSelected)
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (row: any) => selected.indexOf(row) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.tableData.length) : 0;

    const handleClickStartOpen = () => {
        setOpen(true);
    };
  
    const handleSearch = e => {
        let target = e.target;
        setFilterFunc({
            fn: items => {
                if (target.value == "")
                    return items;
                else
                    return items.filter(x => {
                        return x.email.includes(target.value)
                    }
                    )
            }
        })
    }

    return (
        <>
            <Toolbar style={{ marginLeft: "-30px" }}>
                <Search
                    label="Search by Email"
                    InputProps={{
                        startAdornment: (<InputAdornment position='start'>
                            <SearchIcon sx={{ fontSize: "16px" }} />
                        </InputAdornment>)
                    }}
                    onChange={handleSearch}
                />
            </Toolbar>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Stack alignItems="center">
                            {props?.loading && <CircularProgress />}
                        </Stack>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={props.tableData.length}
                            />
                            <TableBody>
                                {stableSort(filterFunc.fn(props?.tableData), getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <>
                                                {!props?.tableData.length ? <tr>No Users</tr> : <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.userName}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.userName}
                                                    </TableCell>
                                                    {/* <TableCell align="left">{row.id}</TableCell> */}
                                                    <TableCell align="left">{row.email}</TableCell>
                                                    <TableCell align="left">{row.createdAt}</TableCell>
                                                    <TableCell align="left">{row.updatedAt}</TableCell>
                                                    <TableCell align="left">
                                                        <Button size="small" onClick={handleClickStartOpen}
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            Permissions
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>}
                                                <UserModal
                                                    open={open}
                                                    setOpen={setOpen}
                                                    getInstancesList={getInstancesList}
                                                    userSelected={props.userSelected}
                                                    setUserSelected={props.setUserSelected}
                                                    userName={props.userSelected[0]?.userName}
                                                    emailId={props.userSelected[0]?.email}
                                                    createdAt={props.userSelected[0]?.createdAt}
                                                    updatedAt={props.userSelected[0]?.updatedAt}
                                                    userTableData={userTableData}
                                                    unassignedInstances={unassignedInstances}
                                                    checkedInstances={checkedInstances}
                                                    setCheckedInstances={setCheckedInstances}
                                                    checkedInstancesId={checkedInstancesId}
                                                    setCheckedInstancesId={setCheckedInstancesId}
                                                    loading={loading}
                                                    accountName={accountName}
                                                    setAccountName={setAccountName}
                                                    instanceSelected={instanceSelected}
                                                    setInstanceSelected={setInstanceSelected}
                                                    setSelected={setSelected}
                                                />
                                            </>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box >
        </>
    );
}