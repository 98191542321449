import * as React from 'react';
import { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { DialogBox } from './DialogBox'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import './Table.css'
import { Button, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AutoComplete from '../../Components/SelectLabels/Autocomplete';
import _ from "lodash";
import jwt from "jwt-decode"
import { ApiService } from '../../Services/boralSelfServeApis'
const apiService = new ApiService()

interface Data {
    instanceName: string;
    instanceType: string;
    instanceId: string;
    status: string;
    boralSchedule: string;
    applicationName: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'instanceName',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'instanceType',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'instanceId',
        numeric: false,
        disablePadding: false,
        label: 'Instance ID',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'boralSchedule',
        numeric: false,
        disablePadding: false,
        label: 'Boral Schedule',
    },
    {
        id: 'applicationName',
        numeric: false,
        disablePadding: false,
        label: 'Application Name',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontWeight: "bold" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Instance Details
                </Typography>
            )}
        </Toolbar>
    );
}


export default function EnhancedTable(props) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('instanceType');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [selectedStatus, setSelectedStatus] = React.useState<readonly string[]>([]);
    const [selectedIds, setSelectedIds] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [check, setCheck] = React.useState(false)
    const [tableData, setTableData] = React.useState<any[]>([])
    const [isRefresh, setIsRefresh] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [accountList, setAccountList] = React.useState<any>([])
    const [accountName, setAccountName] = React.useState<any>()

    const token: any = sessionStorage.getItem('idToken')
    const decoded: any = jwt(token)
    const userName = decoded.username

    const header = React.useMemo(() => ({
        headers: {
            Authorization: token,
        }
    }), [token])

    const getAccountNames = React.useCallback(async () => {
        let _response = await apiService.assignUserPermissions({ username: userName }, header)
        let accountNames = _response.data.data.items.map(i => i.accountName)
        let accounts = [...new Set(accountNames)]
        setAccountList(accounts)
        setAccountName(accounts[0])
    }, [header, userName])

    const getInstances = React.useCallback(async () => {
        try {
            setLoading(true)
            const _dynamoDbInstanceList = await apiService.getInstances({ accountName, username: userName }, header)
            const _tableData = _dynamoDbInstanceList.data.data.items
            const _instanceIds = _dynamoDbInstanceList.data.data.items.map(i => i.SK.slice(9))
            let _ec2InstanceList, _dynamoDbInstanceData
            if (_instanceIds?.length) {
                _ec2InstanceList = await apiService.getInstanceStatus({ accountName, instanceIds: _instanceIds }, header)

                const _instanceStatus = _ec2InstanceList.data.data.Reservations

                _dynamoDbInstanceData = _tableData.map((i) => ({
                    imageId: i.imageId,
                    instanceId: i.SK.slice(9),
                    instanceName: i.instanceName,
                    instanceType: i.instanceType,
                    status: _instanceStatus?.find((j) => j?.Instances[0]?.InstanceId === i.SK.slice(9))?.Instances[0].State.Name,
                    boralSchedule: i.boralSchedule,
                    applicationName: i.applicationName
                }))

                const _ec2InstanceData = _instanceStatus.map((k, i) => ({

                    imageId: k.Instances[0]?.ImageId,
                    instanceId: k.Instances[0]?.InstanceId,
                    instanceName: k.Instances[0].Tags?.find(item => item.Key === 'Name')?.Value || "-",
                    instanceType: k.Instances[0]?.InstanceType,
                    status: k.Instances[0]?.State.Name,
                    boralSchedule: k.Instances[0].Tags?.find(item => item.Key === 'BoralSchedule')?.Value || "-",
                    applicationName: k.Instances[0].Tags?.find(item => item.Key === 'applicationName')?.Value || "-",
                }))

                const tableArray = _.unionBy(_dynamoDbInstanceData, _ec2InstanceData, 'instanceId')

                setTableData(tableArray)
                setLoading(false)
                return
            }
            setTableData(_dynamoDbInstanceData)
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }, [accountName, header, userName])

    useEffect(() => {
        getAccountNames()
    }, [getAccountNames])


    useEffect(() => {
        getInstances();
    }, [getInstances, isRefresh]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = tableData.map((n) => n.instanceName);
            const newStatus = tableData.map((n) => n.status);
            const newSelectedIds = tableData.map((n) => n.id);
            setSelected(newSelected);
            setSelectedStatus(newStatus);
            setSelectedIds(newSelectedIds);
            setCheck(true)
            return;
        }
        setSelected([]);
        setSelectedStatus([]);
        setSelectedIds([]);
        setCheck(false)
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string, status: string, id: string) => {
        const selectedIndex = selected.indexOf(name);
        const selectedStatusIndex = selectedStatus.indexOf(status)
        const selectedIdIndex = selectedIds.indexOf(id)
        let newSelected: readonly string[] = [];
        let newStatus: readonly string[] = [];
        let newId: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
            newStatus = newStatus.concat(selectedStatus, status)
            newId = newId.concat(selectedIds, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newStatus = newStatus.concat(selectedStatus.slice(1));
            newId = newId.concat(selectedIds.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newStatus = newStatus.concat(selectedStatus.slice(0, -1));
            newId = newId.concat(selectedIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            newStatus = newStatus.concat(
                selectedStatus.slice(0, selectedStatusIndex),
                selectedStatus.slice(selectedStatusIndex + 1),
            );
            newId = newId.concat(
                selectedIds.slice(0, selectedIdIndex),
                selectedIds.slice(selectedIdIndex + 1)
            );
        }

        setSelected(newSelected);
        setSelectedStatus(newStatus)
        setSelectedIds(newId)

    };

    useEffect(() => {

    }, [check])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRefresh = () => {
        setIsRefresh(!isRefresh)
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

    const runningIcon = <CheckCircleOutlineIcon sx={{ fontSize: "20px" }} />
    const stoppedIcon = <RemoveCircleOutlineIcon sx={{ fontSize: "20px" }} />
    const timerIcon = <AccessTimeIcon sx={{ fontSize: "20px" }} />

    return (
        <>
            { accountList.length ? <div>
            <div className="account-dropdown">
                <AutoComplete value={accountName} options={accountList} minWidth={400} size="small"
                    onChange={(newValue, propName) => {
                        setAccountName(newValue)
                    }} />
            </div><br /><br />
            <Box sx={{ width: '98%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Stack alignItems="center">
                            {loading && <CircularProgress />}
                        </Stack>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={tableData?.length}
                            />
                            <TableBody style={{ position: 'relative' }} >
                                {stableSort(tableData, getComparator(order, orderBy))
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    ?.map((row, index) => {
                                        const isItemSelected = isSelected(String(row.instanceName));
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, String(row.instanceName), String(row.status), String(row.instanceId))}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.instanceName}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.instanceName}
                                                </TableCell>
                                                <TableCell align="left">{row.instanceType}</TableCell>
                                                <TableCell align="left">{row.instanceId}</TableCell>
                                                <TableCell style={row.status === "running" ? { color: "green" } : row.status === "stopping" ? { color: "grey" } : row.status === "pending" ? { color: "grey" } : { color: "red" }} align="left">
                                                    {row.status === "running" ? <span className='statusStyle'>{runningIcon}Running</span> : row.status === "stopped" ? <span className='statusStyle'>{stoppedIcon}Stopped</span> : row.status === "stopping" ? <span className='statusStyle'>{timerIcon}Stopping</span> : row.status === "pending" ? <span className='statusStyle'>{timerIcon}Pending</span> : row.status === "terminated" ? <span className='statusStyle statusColorGrey'>{stoppedIcon}Terminated</span> : ""}
                                                </TableCell>
                                                <TableCell align="left">{row.boralSchedule}</TableCell>
                                                <TableCell align="left">{row.applicationName}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="footer">
                        <div style={{ display: "flex", justifyContent: "start", alignItems: 'center' }}>
                            <Button onClick={handleRefresh}>
                                <RefreshIcon />
                            </Button>
                            <DialogBox selectedIds={selectedIds} selected={selected} selectedStatus={selectedStatus} check={check} accountName={accountName} initializeData={getInstances} setSelected={setSelected} setSelectedStatus={setSelectedStatus} setSelectedIds={setSelectedIds} />
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={tableData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </div>
                </Paper>
            </Box>
            </div> : "No Instances Assigned"}
        </>
    );
}