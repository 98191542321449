import React, { useEffect, useState } from 'react'
import Dashboard from "./Dashboard"
import UserTable from "./Users"
import Instances from "./Instances"
import { ApiService } from '../../Services/boralSelfServeApis'
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

const apiService = new ApiService();

export const AdminDashboard = () => {

  const [tableData, setTableData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [instanceSelected, setInstanceSelected] = useState([])
  const [userSelected, setUserSelected] = useState<any>([])
  const [check, setCheck] = useState<any>([])

  const token = sessionStorage.getItem('idToken')

  const header = {
    headers: {
      Authorization: token
    }
  };

  const getUsers = React.useCallback(async () => {
    try {
      setLoading(true)
      const _response = await apiService.getUsers(header);
      const _userList = _response.data.data.Users

      const userData = _userList?.map((k, i) => ({
        userName: k.Username,
        email: k.Attributes.find(item => item.Name === 'email').Value,
        createdAt: new Date(k.UserCreateDate).toUTCString().slice(5),
        updatedAt: new Date(k.UserLastModifiedDate).toUTCString().slice(5)
      }))

      setTableData(userData)
      setLoading(false)

    } catch (err) {
      console.log(err);
    }
  }, [])

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Dashboard />
      <div style={{ marginLeft: "220px", marginTop: "-35px" }}>
        <UserTable setCheck={setCheck} tableData={tableData} loading={loading} userSelected={userSelected} setUserSelected={setUserSelected} />
        <br /><br />
        <Instances instanceSelected={instanceSelected} setInstanceSelected={setInstanceSelected} tableData={tableData} />
      </div>
      <ToastContainer position='bottom-left' />
    </>
  )
}
